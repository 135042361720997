import { DocumentType, InquiryType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import { InquiryData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Title from '~/components/Header/Title';
import CMSView from '~/components/View';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const DashboardPage: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
}) => {
  const { t } = useTranslation();
  const [inquiries, setInquiries] = useState<DocumentType<InquiryType>[]>();

  useEffect(() => {
    const inquiryData = new InquiryData({ espaceId: espace.id });
    const unsub = inquiryData.watch(
      docs => {
        setInquiries(docs);
      },
      {
        wheres: {
          archived: false,
          parent: '',
        },
      },
    );

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [espace.id]);

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <div className="mt-1 md:mt-6 mx-6">
          <Title>{`Bonjour ${user.firstName},`}</Title>
          <p className="mt-6 text-md">
            {undefined !== inquiries && inquiries.length > 0
              ? t('dashboard.inquiries.waiting.label', {
                  count: inquiries.length,
                })
              : t('dashboard.messages.good-day.label')}
          </p>
        </div>
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(DashboardPage);
