import classnames from 'classnames';
import React, { FC } from 'react';

import { ViewProps } from '~/components/View/props';

const ViewSplit: FC<ViewProps> = ({ className, children }) => (
  <div className={classnames('cms-view-split flex space-x-6', className)}>
    {children}
  </div>
);

export default ViewSplit;
